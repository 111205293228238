import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "saft-service"
    }}>{`Saft-Service`}</h1>
    <p>{`Saft-Service is a micro service to generate SAF-T Financial reports in accordance with the new bookkeeping
regulations that came into force January 2019. It collects the data from other services in the Entur
environment, such as the Electronic Journal and POS-register, and it then generates a SAF-T Report (.xml format)
which it then delivers to Altinn.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`SAF-T`}</strong>{` = Standard Audit File — Tax`}</li>
      <li parentName="ul">{`Skattetaten's websites on the matter —
`}<a parentName="li" {...{
          "href": "https://www.skatteetaten.no/en/business-and-organisation/starte-og-drive/rutiner-regnskap-og-kassasystem/saf-t-financial/"
        }}>{`SAF-T Financial`}</a>{`.`}</li>
    </ul>
    <p><img alt="Saft Context" src={require("./saft_context.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      